<template>
    <div style="position: relative; overflow:visible;">
        <div class="slide-show" v-touch:swipe.left="goNext" v-touch:swipe.right="goPrevious">
            <div class="slide-item-wrapper" v-bind:class="{'ts': enabledTs}" v-bind:style="tl">
                <div class="slide-show-item" v-for="(image, index) in images" :key="index" v-bind:style="image.tl">
                    <img v-if="$globalData.isMb" style="width:100%;" :src="image.path_mb" />
                    <img v-if="!$globalData.isMb" style="width:100%;" :src="image.path" />
                </div>
            </div>
            <div class="down-arrow-wrapper"><img class="down-arrow" src="../assets/down_arrow.png" /></div>
        </div>
        <div class="dot-wrapper" v-if="isShowDotWrapper" v-bind:style="dotWrapperLeft">
            <div class="dot" v-bind:class="{'dot-active': currentPosition == index + 1, 'dot-inactive': currentPosition != index + 1}" v-for="(image, index) in images" :key="index" v-on:click="go(index + 1)" />
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import {
        GET_SLIDE_SHOW_IMAGES_API_PATH
    } from '../constants';

    export default {
        name: "AboutSlideShow",
        data() {
            return {
                isMb: null,
                currentPosition: null,
                lastPosition: null,
                images: [],
                enabledTs: true,
                tl: "transform: translate(0, 0);",
                isLockAction: false,
                intervalObj: null,
                intervalMs: 5000,
                isShowDotWrapper: false,
                dotWrapperLeft: ""
            };
        },
        created: function () {

            let that = this;
            //this.searchProjects = Projects;

            axios.post(GET_SLIDE_SHOW_IMAGES_API_PATH)
                .then(function (res) {
                    that.images = res.data;

                    that.currentPosition = 1;
                    that.recalculateDotWrapperWidth();
                    that.resetAutoPlay();
                });

        },
        methods: {
            go: function (seq) {

                if (this.images == null || this.images.length < 2)
                    return;

                if (this.isLockAction)
                    return;

                if (seq === this.currentPosition)
                    return;

                this.isLockAction = true;
                this.enabledTs = true;
                this.resetAutoPlay();
                this.lastPosition = this.currentPosition;
                this.currentPosition = seq;

                let offset = (this.currentPosition - 1) * 100;
                this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
                this.isLockAction = false;
            },
            goPrevious: async function () {

                if (this.images == null || this.images.length < 2)
                    return;

                if (this.isLockAction)
                    return;

                this.isLockAction = true;
                this.enabledTs = true;
                this.resetAutoPlay();
                this.lastPosition = this.currentPosition;
                this.currentPosition = (this.currentPosition === 1 ? this.images.length : this.currentPosition - 1);

                if (this.lastPosition === 1) {

                    let tsOffset = this.images.length * 100;
                    this.$set(this.images[this.images.length - 1], 'tl', 'transform: translate(-' + tsOffset.toString() + '%, 0);');
                    this.tl = "transform: translate(100%, 0);";
                    await this.sleep(600);

                    this.enabledTs = false;
                    await this.$nextTick();

                    this.$set(this.images[this.images.length - 1], 'tl', 'transform: translate(0, 0);');
                    this.tl = "transform: translate(-" + (tsOffset - 100).toString() + "%, 0);";
                    await this.$nextTick();

                } else {

                    let offset = (this.currentPosition - 1) * 100;
                    this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
                }

                this.isLockAction = false;
            },
            goNext: async function () {

                if (this.images == null || this.images.length < 2)
                    return;

                if (this.isLockAction)
                    return;

                this.isLockAction = true;
                this.enabledTs = true;
                this.resetAutoPlay();
                this.lastPosition = this.currentPosition;
                this.currentPosition = (this.currentPosition === this.images.length ? 1 : this.currentPosition + 1);

                if (this.lastPosition === this.images.length) {

                    let tsOffset = this.images.length * 100;
                    this.$set(this.images[0], 'tl', 'transform: translate(' + tsOffset.toString() + '%, 0);');
                    this.tl = "transform: translate(-" + tsOffset.toString() + "%, 0);";
                    await this.sleep(600);

                    this.enabledTs = false;
                    await this.$nextTick();

                    this.$set(this.images[0], 'tl', 'transform: translate(0, 0);');
                    this.tl = "transform: translate(0, 0);";
                    await this.$nextTick();
                }
                else {

                    let offset = (this.currentPosition - 1) * 100;
                    this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
                }

                this.isLockAction = false;
            },
            resetAutoPlay: function () {

                let that = this;
                if (this.intervalObj != null)
                    clearInterval(this.intervalObj);

                this.intervalObj = setInterval(() => { that.goNext(); }, this.intervalMs);
            },
            recalculateDotWrapperWidth: function () {

                if (this.images != null && this.images.length > 1) {

                    if (this.$globalData.isMb)
                        this.dotWrapperLeft = 'left: calc(50% - ' + (((this.images.length * 23) - 15) / 2) + 'px);';
                    else
                        this.dotWrapperLeft = 'left: calc(50% - ' + (((this.images.length * 25) - 15) / 2) + 'px);';

                    this.isShowDotWrapper = true;
                }
            },
            sleep: function (ms) {
                return new Promise((resolve) => {
                    setTimeout(resolve, ms);
                });
            }
        }
    };
//that.$nextTick(function () {

//});
</script>