<template>
  <div>
    <section>
      <AboutSlideShow />
    </section>
    <section>
      <div class="about-section2">
        <img
          class="full-width-img"
          v-if="!$globalData.isMb"
          src="../assets/about_img_001.jpg"
        />
        <img
          class="full-width-img"
          v-if="$globalData.isMb"
          src="../assets/mb_about_img_001.jpg"
        />
        <div class="about-section2-content-wrapper1">
          <div class="title">關於我們</div>
          <div class="content">
            擁有十多年資深設計經驗的設計師，及專業裝修工程夥伴，將客人心目中的夢想居室變成真實的安樂窩。
          </div>
          <button class="about-button" v-on:click="aboutMore">了解更多</button>
        </div>
        <div class="about-section2-content-wrapper2">
          <div class="content">為客人打造心目中的</div>
          <div class="title">安樂窩</div>
          <div class="about-border"></div>
          <button class="about-button" v-on:click="projectMore">
            了解更多
          </button>
        </div>
        <div class="about-section2-content-wrapper3">
          <div class="title">最新動向</div>
          <div class="about-border"></div>
          <button class="about-button" v-on:click="newsMore">了解更多</button>
        </div>
      </div>
    </section>
    <section v-if="$globalData.isMb">
      <div class="about-section-3">
        <img src="../assets/about_img_002.jpg" />
        <div class="wrapper">
          <div class="title-wrapper">
            <div class="title">媒體</div>
            <div class="title">介紹</div>
          </div>
          <div class="about-border"></div>
          <div class="link-wrapper">
            <a class="link" v-on:click="mediaTv">電視</a><br />
            <a class="link" v-on:click="mediaBook">雜誌</a><br />
            <a class="link" v-on:click="mediaOnline">網上專訪</a><br />
            <a class="link" v-on:click="mediaOther">其他媒體</a>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!$globalData.isMb">
      <div class="about-section-3">
        <div class="wrapper">
          <div class="title-wrapper">
            <div class="title">媒體</div>
            <div class="title">介紹</div>
          </div>
          <div class="about-border"></div>
          <div class="link-wrapper">
            <a class="link" v-on:click="mediaTv">電視</a><br />
            <a class="link" v-on:click="mediaBook">雜誌</a><br />
            <a class="link" v-on:click="mediaOnline">網上專訪</a><br />
            <a class="link" v-on:click="mediaOther">其他媒體</a>
          </div>
        </div>
        <img src="../assets/about_img_002.jpg" />
      </div>
    </section>
    <section id="contact-us" class="about-section-4">
      <div class="container-fluid content-wrapper">
        <div
          class="row"
          v-if="$globalData.isMb"
          style="text-align: center; padding: 20px 0 20px 0"
        >
          <div
            style="
              margin-bottom: 10px;
              font-size: 2.75em;
              font-weight: 300;
              letter-spacing: 5px;
            "
          >
            查詢更多服務?
          </div>
          <div>
            <div
              style="
                margin-bottom: 5px;
                padding: 8px;
                display: inline-block;
                vertical-align: top;
              "
            >
              <img
                src="../assets/icon_phone.png"
                style="width: 26px; height: 26px"
              />
            </div>
            <div
              style="
                display: inline-block;
                border-left: 1px solid #fff;
                font-size: 28px;
                padding: 0 0 0 10px;
                font-weight: 300;
              "
            >
              2603 6828
            </div>
          </div>
          <div>
            <div
              style="padding: 8px; display: inline-block; vertical-align: top"
            >
              <img
                src="../assets/icon_email.png"
                style="width: 26px; height: 26px"
              />
            </div>
            <div
              style="
                display: inline-block;
                border-left: 1px solid #fff;
                font-size: 28px;
                padding: 0 0 0 10px;
                font-weight: 300;
              "
            >
              info@inhomes.com.hk
            </div>
          </div>
        </div>

        <div
          class="row"
          v-if="!$globalData.isMb"
          style="padding: 20px 0 20px 0"
        >
          <div
            class="col-12 col-sm-6"
            style="
              margin-bottom: 10px;
              font-size: 2.75em;
              line-height: 2.75em;
              font-weight: 300;
              letter-spacing: 5px;
            "
          >
            查詢更多服務?
          </div>
          <div
            class="col-12 col-sm-6"
            style="text-align: right; padding-top: 20px"
          >
            <div>
              <div
                style="
                  margin-bottom: 5px;
                  padding: 8px;
                  display: inline-block;
                  vertical-align: top;
                "
              >
                <img
                  src="../assets/icon_phone.png"
                  style="width: 26px; height: 26px"
                />
              </div>
              <div
                style="
                  display: inline-block;
                  border-left: 1px solid #fff;
                  font-size: 28px;
                  padding: 0 0 0 10px;
                  font-weight: 300;
                "
              >
                2603 6828
              </div>
            </div>
            <div>
              <div
                style="padding: 8px; display: inline-block; vertical-align: top"
              >
                <img
                  src="../assets/icon_email.png"
                  style="width: 26px; height: 26px"
                />
              </div>
              <div
                style="
                  display: inline-block;
                  border-left: 1px solid #fff;
                  font-size: 28px;
                  padding: 0 0 0 10px;
                  font-weight: 300;
                "
              >
                info@inhomes.com.hk
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6">
            <input
              type="text"
              class="form-control form-item-custom-style"
              maxlength="5"
              placeholder="姓名"
              v-model="enquiryName"
            />
            <input
              type="text"
              class="form-control form-item-custom-style"
              placeholder="電話"
              v-model="enquiryPhone"
            />
            <input
              type="text"
              class="form-control form-item-custom-style"
              placeholder="電郵"
              v-model="enquiryEmail"
            />
            <input
              type="text"
              class="form-control form-item-custom-style"
              placeholder="單位地址"
              v-model="enquiryAddress"
            />
            <input
              type="text"
              class="form-control form-item-custom-style"
              placeholder="居住人數"
              v-model="enquiryNumberOfPerson"
            />
          </div>
          <div class="col-12 col-sm-6">
            <select
              class="form-select form-item-custom-style"
              v-model="enquiryStyle"
            >
              <option value="" selected disabled>設計風格</option>
              <option value="中式">中式</option>
              <option value="泰式">泰式</option>
              <option value="日式">日式</option>
              <option value="時尚">時尚</option>
              <option value="簡約">簡約</option>
              <option value="典雅">典雅</option>
              <option value="特式主題">特式主題</option>
              <option value="其他">其他</option>
            </select>

            <input
              type="text"
              class="form-control form-item-custom-style"
              placeholder="其他風格 (註明)"
              v-model="enquiryOther"
            />

            <select
              class="form-select form-item-custom-style"
              v-model="enquiryArea"
            >
              <option value="" selected disabled>設計位置</option>
              <option value="全屋 (廳及房間)">全屋 (廳及房間)</option>
              <option value="全屋 (包括廚房及廁所)">
                全屋 (包括廚房及廁所)
              </option>
              <option value="客廳 / 飯廳">客廳 / 飯廳</option>
              <option value="主人房">主人房</option>
              <option value="衣帽間">衣帽間</option>
              <option value="書房 / 客房">書房 / 客房</option>
              <option value="小童房">小童房</option>
              <option value="廚房 / 廁所">廚房 / 廁所</option>
            </select>

            <select
              class="form-select form-item-custom-style"
              v-model="enquiryType"
            >
              <option value="" selected disabled>設計項目</option>
              <option value="傢俬">傢俬</option>
              <option value="傢俬連裝修">傢俬連裝修</option>
            </select>

            <select
              class="form-select form-item-custom-style"
              v-model="enquiryBudget"
            >
              <option value="" selected disabled>預算</option>
              <option value="$60,000">$60,000</option>
              <option value="$100,000">$100,000</option>
              <option value="$150,000">$150,000</option>
              <option value="$200,000 以上">$200,000 以上</option>
              <option value="$300,000">$300,000</option>
              <option value="$500,000">$500,000</option>
              <option value="$700,000">$700,000</option>
              <option value="$1,000,000 以上">$1,000,000 以上</option>
              <option value="其他">其他</option>
            </select>
          </div>
        </div>

        <div class="row" style="padding: 30px 0 30px 0">
          <div class="col-12" style="text-align: center">
            <button
              class="form-button"
              style="margin-right: 25px"
              v-on:click="resetEnquiryForm"
            >
              重設
            </button>
            <button
              class="form-button"
              style="margin-left: 25px"
              v-on:click="submitEnquiryForm"
            >
              提交
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="join-us">
      <div class="about-section-5">
        <img src="../assets/about_img_003.jpg" />
        <div class="text-wrapper">
          <div class="title">加入我們</div>
          <div class="content">
            歡迎加入我們室內設計團隊。這裏，我們賞識並鼓勵你施展才華，帶給你美好的工作體驗，充分發揮你的潛能。有興趣可電郵履歷表約見：info@inhomes.com.hk
          </div>
        </div>
      </div>
    </section>
    <section class="about-section-6">
      <div class="container-fluid" style="padding: 0">
        <div class="row" style="margin: 0">
          <div class="col-12 col-sm-12 col-md-12 home-google-map-section-left">
            <div class="map-wrapper">
              <!--<iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7375.322945958913!2d114.030206!3d22.441764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8c6c4de525a22095!2sInhome&#39;s%20Interior%20Design!5e0!3m2!1sen!2shk!4v1641308472284!5m2!1sen!2shk" allowfullscreen="" loading="lazy"></iframe>-->
              <iframe
                class="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.5635392480062!2d114.02932206480101!3d22.442130812384523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403fa0a10779e19%3A0x8c6c4de525a22095!2z5Z6L5a625a6k5YWn6Kit6KiI!5e0!3m2!1szh-TW!2shk!4v1648452287936!5m2!1szh-TW!2shk"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div style="padding: 20px 0 20px 10px">
              <div>
                <div
                  style="
                    margin-bottom: 5px;
                    padding: 0 10px 0 10px;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    vertical-align: top;
                    border-right: 1px solid #fff;
                  "
                >
                  <img
                    src="../assets/icon_location.png"
                    style="
                      width: 20px;
                      height: 20px;
                      transform: translateY(-2px);
                    "
                  />
                </div>
                <div
                  style="
                    display: inline-block;
                    width: calc(100% - 50px);
                    font-size: 1em;
                    padding: 0 0 0 10px;
                    font-weight: 300;
                  "
                >
                  <span style="letter-spacing: 2px"
                    >元朗合益路65-71號金堂樓地下5號舖</span
                  ><br />
                  SHOP 5, G/F, KAM TONG BUILDING, 65-71 HOP YICK ROAD, YUEN
                  LONG, N.T.
                </div>
              </div>
              <div style="margin-top: 5px">
                <div
                  style="
                    margin-bottom: 5px;
                    padding: 0 10px 0 10px;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    vertical-align: top;
                    border-right: 1px solid #fff;
                  "
                >
                  <img
                    src="../assets/icon_phone.png"
                    style="
                      width: 20px;
                      height: 20px;
                      transform: translateY(-2px);
                    "
                  />
                </div>
                <div
                  style="
                    display: inline-block;
                    width: calc(100% - 50px);
                    font-size: 1em;
                    padding: 0 0 0 10px;
                    font-weight: 300;
                  "
                >
                  <span style="letter-spacing: 2px">2603 6900</span>
                </div>
              </div>
              <div style="margin-top: 5px">
                <div
                  style="
                    margin-bottom: 5px;
                    padding: 0 10px 0 10px;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    vertical-align: top;
                    border-right: 1px solid #fff;
                  "
                >
                  <img
                    src="../assets/icon_fax.png"
                    style="
                      width: 20px;
                      height: 20px;
                      transform: translateY(-2px);
                    "
                  />
                </div>
                <div
                  style="
                    display: inline-block;
                    width: calc(100% - 50px);
                    font-size: 1em;
                    padding: 0 0 0 10px;
                    font-weight: 300;
                  "
                >
                  <span style="letter-spacing: 2px">2603 6977</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-12 col-md-6 home-google-map-section-right">
                        <div class="map-wrapper">
                            <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7381.5146396135115!2d114.209042!3d22.325016!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xffe7ed13f0fd55ac!2z5a-26ZqG5Lit5b-D!5e0!3m2!1szh-TW!2shk!4v1641309595276!5m2!1szh-TW!2shk" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                        <div style="padding: 20px 0 20px 10px;">
                            <div>
                                <div style="margin-bottom: 5px; padding: 0 10px 0 10px; display: inline-block; width: 40px; height: 24px; vertical-align: top; border-right: 1px solid #fff; "><img src="../assets/icon_location.png" style="width: 20px; height: 20px; transform: translateY(-2px);" /></div>
                                <div style="display: inline-block;width: calc(100% - 50px); font-size: 1em; padding: 0 0 0 10px; font-weight: 300;">
                                    <span style="letter-spacing: 2px">九龍灣宏照道11號寶隆中心A座916室</span><br />
                                    ROOM 916, BLOCK A, PO LUNG CENTRE, 11 WANG CHIU ROAD, KOWLOON BAY, KLN.
                                </div>
                            </div>
                            <div style="margin-top: 5px;">
                                <div style="margin-bottom: 5px; padding: 0 10px 0 10px; display: inline-block; width: 40px; height: 24px; vertical-align: top; border-right: 1px solid #fff; "><img src="../assets/icon_phone.png" style="width: 20px; height: 20px; transform: translateY(-2px);" /></div>
                                <div style="display: inline-block;width: calc(100% - 50px); font-size: 1em; padding: 0 0 0 10px; font-weight: 300;">
                                    <span style="letter-spacing: 2px">2669 9881</span>
                                </div>
                            </div>
                            <div style="margin-top: 5px;">
                                <div style="margin-bottom: 5px; padding: 0 10px 0 10px; display: inline-block; width: 40px; height: 24px; vertical-align: top; border-right: 1px solid #fff; "><img src="../assets/icon_fax.png" style="width: 20px; height: 20px; transform: translateY(-2px);" /></div>
                                <div style="display: inline-block;width: calc(100% - 50px); font-size: 1em; padding: 0 0 0 10px; font-weight: 300;">
                                    <span style="letter-spacing: 2px">2669 9887</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AboutSlideShow from "./AboutSlideShow";
import axios from "axios";
import { SUBMIT_ENQUIRY_API_PATH } from "../constants";

export default {
  name: "Home",
  components: {
    AboutSlideShow,
  },
  mounted: function () {
    if (typeof this.$route.params.section !== "undefined") {
      let that = this;
      let sect = this.$route.params.section;

      if (sect === "contactUs") {
        that.$router.push({ path: "/" });

        setTimeout(function () {
          window.scrollTo(
            window.scrollX,
            document.getElementById("contact-us").offsetTop -
              (that.$globalData.isMb ? 60 : 80)
          );
        }, 800);
        //setTimeout(function () { window.scrollTo(window.scrollX, (document.getElementById('contact-us').offsetTop) - (that.$globalData.isMb ? 60 : 80)); }, 550);
        //this.$router.push({ path: '/', hash: '#contact-us' });
      } else if (sect === "joinUs") {
        that.$router.push({ path: "/" });

        setTimeout(function () {
          window.scrollTo(
            window.scrollX,
            document.getElementById("join-us").offsetTop -
              (that.$globalData.isMb ? 60 : 80)
          );
        }, 800);
        //setTimeout(function () { window.scrollTo(window.scrollX, (document.getElementById('join-us').offsetTop) - (that.$globalData.isMb ? 60 : 80)); }, 550);
        //this.$router.push({ path: '/', hash: '#join-us' });
      }
      //window.scrollTo(window.scrollX, window.scrollY - 80);
    }
  },
  methods: {
    mediaTv: function () {
      this.$router.push({ name: "Media", params: { type: "Tv" } });
    },
    mediaBook: function () {
      this.$router.push({ name: "Media", params: { type: "Book" } });
    },
    mediaOnline: function () {
      this.$router.push({ name: "Media", params: { type: "Online" } });
    },
    mediaOther: function () {
      this.$router.push({ name: "Media", params: { type: "Other" } });
    },
    aboutMore: function () {
      this.$router.push({ path: "/About" });
    },
    projectMore: function () {
      this.$router.push({ path: "/Project" });
    },
    newsMore: function () {
      this.$router.push({ path: "/News" });
    },
    resetEnquiryForm: function () {
      this.enquiryName = "";
      this.enquiryStyle = "";
      this.enquiryPhone = "";
      this.enquiryOther = "";
      this.enquiryEmail = "";
      this.enquiryArea = "";
      this.enquiryAddress = "";
      this.enquiryType = "";
      this.enquiryNumberOfPerson = "";
      this.enquiryBudget = "";
    },
    submitEnquiryForm: function () {
      let that = this;

      let postData = {
        enquiryName: that.enquiryName,
        enquiryStyle: that.enquiryStyle,
        enquiryPhone: that.enquiryPhone,
        enquiryOther: that.enquiryOther,
        enquiryEmail: that.enquiryEmail,
        enquiryArea: that.enquiryArea,
        enquiryAddress: that.enquiryAddress,
        enquiryType: that.enquiryType,
        enquiryNumberOfPerson: that.enquiryNumberOfPerson,
        enquiryBudget: that.enquiryBudget,
      };

      let isValidName = this.enquiryName.trim() != "";
      let isValidEmail = this.enquiryEmail.trim() != "";
      let isValidPhone = this.enquiryPhone.trim() != "";

      if (isValidName && isValidEmail && isValidPhone) {
        axios
          .post(SUBMIT_ENQUIRY_API_PATH, postData)
          .then((res) => {
            if (res.data.result) alert("您的查詢已經發出。");
            else alert("發生問題，請稍後再試");
          })
          .catch((error) => alert("發生問題，請稍後再試"));
      } else alert("請輸入姓名，電話及有效的電郵。");
    },
  },
  data() {
    return {
      enquiryName: "",
      enquiryStyle: "",
      enquiryPhone: "",
      enquiryOther: "",
      enquiryEmail: "",
      enquiryArea: "",
      enquiryAddress: "",
      enquiryType: "",
      enquiryNumberOfPerson: "",
      enquiryBudget: "",
    };
  },
};
</script>

<style>
section.about-section-6 {
  background-color: #9f978a;
  color: #fff;
  padding-top: 50px;
}

.map-wrapper {
  width: 100%;
  height: auto;
  /*filter: grayscale(100%);*/
  z-index: 3;
}

.map-wrapper .map {
  border: 0;
  width: 100%;
  height: 380px;
}
</style>